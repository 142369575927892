.container {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: white;
    text-align: center;
}

h1,
h2 {
    margin: 10px;
}

.answer {
    border: 1px solid #fff;
    padding: 5px 10px 5px 45px;
    width: 610px;
    text-align: left;
    margin-top: 10px;
    position: relative;
    font-size: 22px;
    cursor: pointer;
    -webkit-transition: ease-in-out, width 0.35s ease-in-out;
    transition: ease-in-out, width 0.35s ease-in-out;
}

.answer.selected {
    color: #4f4c4c;
    background-color: #c8ffbb;
}

.answer:hover {
    color: #4f4c4c;
    background-color: #c8ffbb;
}

.answer:focus {
    outline: none;
}

.results span.correct {
    color: #c8ffbb;
}

.results span.failed {
    color: #f27c7c;
}

.error {
    color: #f27c7c;
}

.letter {
    color: #4f4c4c;
    width: 30px;
    position: absolute;
    left: 0;
    text-align: center;
    height: 28px;
    top: 0;
    padding: 5px;
    text-transform: uppercase;
}

.btn {
    color: #4f4c4c;
    background-color: #f4f4f4;
    padding: 10px 50px;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}

.btn-primary {
    color: #4f4c4c;
    background-color: #ffc107;
    font-weight: bold;
}
